(function ($) {
  $(function () {
    function fixViewport() {
      let header = $('header');
      let main = $('main#main-content');
      let footer = $('footer');
      let viewportHeight = window.innerHeight;
      let overallHeight =
        header.outerHeight() + main.outerHeight() + footer.outerHeight();
      let newViewport =
        viewportHeight - header.outerHeight() - footer.outerHeight();
      if (viewportHeight > overallHeight)
        main.css('height', newViewport + 'px');
    }

    fixViewport();

    let resizeSliderBarTimeout;
    // Add a timeout so this isn't being called literally all the time
    $(window).resize(function () {
      clearTimeout(resizeSliderBarTimeout);
      resizeSliderBarTimeout = setTimeout(fixViewport(), 250);
    });
  });
})(jQuery);
