(function ($) {
  $(function () {
    let $employees = $('.employees-slider');
    if (!$employees.length) return;

    const slickSettings = {
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      arrows: true,
      prevArrow:"<button type='button' class='slick-arrow slick-prev'><p class='sr-only'>Previous</i></button>",
      nextArrow:"<button type='button' class='slick-arrow slick-next'><p class='sr-only'>Next</p></button>"
      // dots: true,
      // responsive: [
      //   {
      //     breakpoint: 1240,
      //     settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 1,
      //       infinite: true,
      //       dots: true,
      //     },
      //   },
      //   {
      //     breakpoint: 992,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 1,
      //     },
      //   },
      //   {
      //     breakpoint: 768,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //     },
      //   },
      // ],
    };

    $employees.slick(slickSettings);
  });
})(jQuery);
