(function ($) {
  $(function () {
    let $newslist = $('.news-list');
    if (!$newslist.length) return;

    const slickSettings = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    $newslist.slick(slickSettings);

    $('.news-select-filter').select2({
      minimumResultsForSearch: -1,
      placeholder: 'Filter',
      allowClear: true,
    });

    $('.news-select-filter').on('select2:select', function (e) {
      let data = e.params.data;
      let selected = data.id;
      if (selected === 'all') {
        $newslist.slick('unslick');
        $('.news-list article').show();
        $newslist.slick(slickSettings);
      } else if (selected !== 'custom') {
        $newslist.slick('unslick');
        $('.news-list article').hide();
        $('.news-list article[data-term="' + selected + '"]').show();
        $newslist.slick(slickSettings);
      } else {
        $newslist.slick('unslick');
        $('.news-list article').hide();
        $('.news-list article[data-term="custom"]').show();
        $newslist.slick(slickSettings);
      }
    });
  });
})(jQuery);
