(function ($) {
  $(function () {
    if (!$('.search-content').length) return;
    $('form.search-form').submit(function (e) {
      if (!$('input[id="article-search"]').val().trim().length) {
        alert('Please enter a non-blank search.');
        e.preventDefault();
      }
    });
  });
})(jQuery);
