(function ($) {
  $(function () {
    function toggleMenu() {
      if ($('.menu-content-wrap').hasClass('d-none')) {
        $('.menu-content-wrap').removeClass('d-none');
      }
      $('.menu-content-wrap').toggleClass('closed');
      $('body').toggleClass('menu-open');
    }
    function openMenu() {
      if ($('.menu-content-wrap').hasClass('closed')) {
        $('.menu-content-wrap').removeClass('closed');
        $('body').addClass('menu-open');
      } else {
        $('.menu-content-wrap').addClass('closed');
        $('body').removeClass('menu-open');
      }
    }
    $('main#main-content').click(function () {
      if (!$('.menu-content-wrap').hasClass('closed')) {
        toggleMenu();
      }
    });
    $('button.menu-toggle-button').click(function () {
      toggleMenu();
    });

    $('.menu-toggle-button.open-menu').focus(function () {
      if ($('.menu-content-wrap').hasClass('d-none')) {
        $('.menu-content-wrap').removeClass('d-none');
      }
    });
    $('.menu-toggle-button.open').focus(function () {
      openMenu();
    });
    $('.hide-menu-tab').focus(function () {
      openMenu();
    });

    // let resizeSliderBarTimeout;
    // Add a timeout so this isn't being called literally all the time
    $(window).resize(function () {
      if ($('.menu-content-wrap').hasClass('closed')) {
        $('.menu-content-wrap').addClass('d-none');
      }
      // clearTimeout(resizeSliderBarTimeout);
      // resizeSliderBarTimeout = setTimeout($('.menu-content-wrap').removeClass('d-none'), 500);
    });
  });
})(jQuery);
