(function ($) {
  $(function () {
    const sectionExists = $('section.inner_page_banner');
    if (!sectionExists.length) return;
    setTimeout(function () {
      resizedEnded();
    }, 50);
    var resizeId;
    $(window).resize(function () {
      clearTimeout(resizeId);
      resizeId = setTimeout(resizedEnded, 250);
    });

    function resizedEnded() {
      const titlePosLeft = $('section.inner_page_banner h1.title').offset()
        .left;
      $('.list-item-link').each(function () {
        const thisOne = $(this).offset().left;
        const sum =
          titlePosLeft - (thisOne - parseInt($(this).css('margin-left'), 10));
        if (sum > 0) $(this).css('margin-left', sum + 'px');
      });
    }
  });
})(jQuery);
